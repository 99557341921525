import api from 'api';
import axios from 'axios';
import qs from 'qs';

const APP_API = process.env.REACT_APP_API_URL;
const EMAIL_API = process.env.REACT_APP_PHP_API_URL;

/**
 * Approved User
 */
interface ApprovedUserParams {
	userName: string;
	userEmail: string;
}

const sendEmailApprovedUser = async (data: ApprovedUserParams) => {
	const response = await axios({
		method: 'post',
		url: EMAIL_API + api.EMAIL.APPROVED_USER_EMAIL,
		data: qs.stringify(data)
	});

	return response.data;
};
const sendEmail = async (formData: any) => {
	try {
		const response = await fetch(process.env.REACT_APP_API_MAIL_URL as any, {
			method: 'POST',
			body: formData
		});

		const result = await response.json();
		if (!response.ok) {
			console.error('Failed to send email:', result.error);
		}
		return response;
	} catch (error) {
		console.error('Error sending email:', error);
	}
};
/**
 * Ticket
 */
interface SendEmailTicketParams {
	id: number;
	pnr: string;
	address: string;
	email: string;
}

const sendEmailTicket = async (data: SendEmailTicketParams) => {
	const response = await axios({
		method: 'get',
		url: EMAIL_API + api.EMAIL.SEND_TICKET_EMAIL,
		params: {data: {...data, url: APP_API}}
	});

	return response.data;
};

interface SendEmailTicketCancelParams {
	pnr: string;
	email: string;
}

const sendEmailTicketCancel = async (data: SendEmailTicketCancelParams) => {
	const response = await axios({
		method: 'get',
		url: EMAIL_API + api.EMAIL.SEND_BOOKING_CANCEL_EMAIL,
		params: {data: {...data, url: APP_API}}
	});

	return response.data;
};

interface SendEmailTicketRefundParams {
	pnr: string;
	email: string;
}

const sendEmailTicketRefund = async (data: SendEmailTicketRefundParams) => {
	const response = await axios({
		method: 'get',
		url: EMAIL_API + api.EMAIL.SEND_REFUND_TICKET_EMAIL,
		params: {data: {...data, url: APP_API}}
	});

	return response.data;
};

interface SendEmailBookingAddParams {
	pnr?: string;
	email: string;
	type: string;
	managerName?: string;
	managerEmail?: string;
}

const sendEmailBookingAdd = async (data: SendEmailBookingAddParams) => {
	const response = await axios({
		method: 'get',
		url: EMAIL_API + api.EMAIL.SEND_BOOKING_ADD_EMAIL,
		params: {data: {...data, url: APP_API}}
	});

	return response.data;
};

interface SendEmailIssueTicketParams {
	pnr: string;
	email: string;
}

const sendEmailIssueTicket = async (data: SendEmailIssueTicketParams) => {
	const response = await axios({
		method: 'get',
		url: EMAIL_API + api.EMAIL.SEND_ISSUE_TICKET_EMAIL,
		params: {data: {...data, url: APP_API}}
	});

	return response.data;
};

/**
 * Invoice
 */
interface SendEmailInvoiceParams {
	pnr?: string;
	guesName?: string;
	address: string;
	email: string;
}

const sendEmailInvoice = async (data: SendEmailInvoiceParams) => {
	const response = await axios({
		method: 'get',
		url: EMAIL_API + api.EMAIL.SEND_INVOICE_EMAIL,
		params: {data: {...data, url: APP_API}}
	});

	return response.data;
};

/**
 * Receipt
 */
interface SendEmailReceiptParams {
	pnr: string;
	email: string;
}

const sendEmailReceipt = async (data: SendEmailReceiptParams) => {
	const response = await axios({
		method: 'get',
		url: EMAIL_API + api.EMAIL.SEND_RECEIPT_EMAIL,
		params: {data: {...data, url: APP_API}}
	});

	return response.data;
};

interface RegistrationConfirmParams {
	veryficationToken: string;
	userEmail: string;
	url: string;
}

const sendEmailRegistrationConfirm = async (data: RegistrationConfirmParams) => {
	const response = await axios({
		method: 'post',
		url: EMAIL_API + api.EMAIL.REGISTER_CONFIRM_EMAIL,
		data: qs.stringify(data)
	});

	return response.data;
};

interface ResetPasswordParams {
	veryficationToken: string;
	userEmail: string;
	url: string;
}

const sendEmailResetPassword = async (data: ResetPasswordParams) => {
	const response = await axios({
		method: 'post',
		url: EMAIL_API + api.EMAIL.RESET_PASSWORD_EMAIL,
		data: qs.stringify(data)
	});

	return response.data;
};

const verificateEmail = async (url: string, userId: string) => {
	const response = await axios({
		method: 'get',
		url: url + '&userId=' + userId
	});
	return response.data;
};

const service = {
	sendEmailApprovedUser,
	sendEmailTicket,
	sendEmail,
	sendEmailInvoice,
	sendEmailReceipt,
	sendEmailRegistrationConfirm,
	sendEmailResetPassword,
	verificateEmail,
	sendEmailTicketCancel,
	sendEmailTicketRefund,
	sendEmailIssueTicket,
	sendEmailBookingAdd
};

export default service;
