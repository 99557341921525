// API with token

/**
 * Search
 */
const SEARCH_FLIGHT = '/api/search/mainSearch';
const SEARCH_HOTEL = '/api/hotelSearch';
const SEARCH_PACKAGE = '/api/search/packageSearch';
const PRE_ORDER = '/api/orders/pre-order';
const PRE_ORDER_HOTEL = '/api/hotelOrders/pre-order';
const PRE_ORDER_PACKAGE = '/api/packageOrders/pre-order';
const CREATE_ORDER = '/api/orders/order';
const CREATE_ORDER_PACKAGE = '/api/packageOrders/order';
const CREATE_ORDER_HOTEL = '/api/hotelOrders';
const ORDER_DATE = '/api/orders/orderDate/';

/**
 * Reservations
 */
const GET_ORDERS = '/api/orders/get-orders';
const GET_ORDERS_HOTEL = '/api/hotelOrders/';
const GET_PACKAGE_ORDERS = '/api/packageOrders/get-orders';
const UPDATE_ORDER = '/api/orders/edit/';
const UPDATE_ORDER_HOTEL = '/api/hotelOrders/';
const UPDATE_PACKAGE_ORDER = '/api/packageOrders/edit/';
const CANCEL_PRE_ORDER = '/api/orders/cancel-pre-order/';
const CANCEL_PRE_ORDER_HOTEL = '/api/hotelOrders/cancel-pre-order/';
const CANCEL_PACKAGE_PRE_ORDER = '/api/packageOrders/cancel-pre-order/';
const CANCEL_BOOKING = '/api/orders/cancel/';
const CANCEL_PACKAGE_BOOKING = '/api/packageOrders/cancel/';
const CENCEL_BOOKING_HOTEL = '/api/hotelOrders/cancel/';
const BOOKING_TO_TICKETING = '/api/orders/booking-to-ticketing/';
const BOOKING_TO_TICKETING_PACKAGE = '/api/packageOrders/booking-to-ticketing/';
const BOOKING_TO_TICKETING_HOTEL = '/api/hotelOrders/ticketing/';
const REFUND_TICKET = '/api/orders/refund/';
const REFUND_PACKAGE = '/api/packageOrders/refund/';
const REFUND_HOTEL = '/api/hotelOrders/refund/';
const SPLIT_TICKET = '/api/orders/split/';
const SPLIT_PACKAGE = '/api/packageOrders/split/';
const UPDATE_ORDER_PASSENGER_NAME = '/api/orders/editPassengerName/';

/**
 * Profile
 */
const PROFILE_API = '/api/profile';

/**
 * Agents
 */
const GET_AGENTS = '/api/users/get-users/';
const GET_AGENTS_BY_ID = '/api/users/get-user/';
const GET_BALANCE_HISTORY = '/api/users/balance-history/';
const UPDATE_AGENT = '/api/users/update/';
const REMOVE_PASSENGER = '/api/flights/remove-passenger/';
const ADD_BALANCE = '/api/users/balance/transfer/';
const USE_BALANCE = '/api/users/use-balance/';
const CHANGE_PASSWORD = '/api/users/change-password/';

/**
 * Flights
 */
const GET_FLIGHTS = '/api/flights/get/';
const GET_FLIGHT_CLASSES = '/api/classes/get-by-flightId/';
const CREATE_FLIGHT = '/api/flights/create';
const CREATE_FLIGHT_CLASS = '/api/classes/create/';
const UPDATE_FLIGHT = '/api/flights/edit/';
const UPDATE_FLIGHT_CLASS = '/api/classes/edit/';
const DELETE_FLIGHT = '/api/flights/delete/';
const DELETE_FLIGHT_CLASS = '/api/classes/delete/';

/**
 * Services
 */
const GET_HISTORY = '/api/logs/get';

/**
 * History
 */
const HOTELS = '/api/hotels';
const HOTEL_PROVIDERS = '/api/hotelProviders';
const GET_HOTEL_CATEGORY = '/api/hotels/category';
const GET_HOTEL_ROOM_MEAL = '/api/roomsCategory/mealTypes';
const GET_ROOM_CATEGORY = '/api/roomsCategory';
const ROOMS = 'api/rooms';

/**
 * History
 */
const SERVICES = '/api/services';

/**
 * Reports
 */

const FLIGHT_REPORTS = '/api/reports/orders-full-data';

/**
 * Settings
 */

/* -------------------------------- */
const GET_COMPANY_SETTINGS = '/api/companySettings/';
const GET_TICKET_SETTINGS = '/api/ticketSettings';
const GET_SMTP_SETTINGS = '/api/SMTPsettings';
const GET_TIME_LIMITS = '/api/timeLimits/';
const GET_CURRENCY_TYPES = '/api/currencySettings/currencyCodes';
const NOTIFICATIONS = '/api/notifications/';
const CURRENCY = '/api/currencySettings/';
const CURRENCY_MARGIN_TYPES = '/api/currencySettings/MarginTypes';

const CREATE_AIRPORT = '/api/airport';
const CREATE_COMPANY_SETTING = '/api/companySettings';
const CREATE_TICKET_SETTING = '/api/ticketSettings';
const CREATE_SMTP_SETTING = '/api/SMTPsettings/';
const CREATE_TIME_LIMITS = '/api/timeLimits';

const UPDATE_TICKET_SETTING = '/api/ticketSettings/';
const UPDATE_COMPANY_SETTINGS = '/api/companySettings/edit';
const UPDATE_SMTP_SETTING = '/api/SMTPsettings/edit';
const UPDATE_TIME_LIMITS = '/api/timeLimits/';

/* ------------------------------ */

const GET_AIRPORTS = '/api/airport';
const GET_AIRLINES = '/api/airline';
const GET_AIRCRAFTS = '/api/aircraft';
const GET_FLIGHT_TEMPLATES = '/api/flights/templates';
const GET_FLIGHT_CLASS_TEMPLATES = '/api/classes/templates/';
const GET_BAGGAGE_RULES = '/api/baggageRules';
const GET_FARE_RULES = '/api/fareRules';
const GET_MANAGERS_LIST = '/api/tourManagers';

const CREATE_AIRPORTS = '/api/airport';
const CREATE_AIRLINES = '/api/airline';
const CREATE_AIRCRAFT = '/api/aircraft';
const CREATE_FLIGHT_TEMPLATES = '/api/flights/templates';
const CREATE_FLIGHT_CLASS_TEMPLATES = '/api/classes/templates/';
const CREATE_COMPANY_INFO = '/api/company-info';
const CREATE_FARE_RULE = '/api/fareRules';
const CREATE_BAGGAGE_RULE = '/api/baggageRules';
const CREATE_MANAGER = '/api/tourManagers/';

const UPDATE_FLIGHT_TEMPLATE = '/api/flights/templates/';
const UPDATE_FLIGHT_CLASS_TEMPLATES = '/api/classes/templates/';
const UPDATE_MANAGER = '/api/tourManagers/';
const UPDATE_AIRCRAFT = '/api/aircraft/';
const UPDATE_AIRLINE = '/api/airline/';
const UPDATE_AIRPORTS = '/api/airport/';
const UPDATE_PROVIDERS = '/api/hotelProviders/';
const UPDATE_BAGGAGE_RULE = '/api/baggageRules/';
const UPDATE_FARE_RULE = '/api/fareRules/';

const DELETE_FLIGHT_TEMPLATE = '/api/flights/templates/';
const DELETE_FLIGHT_CLASS_TEMPLATES = '/api/classes/templates/';
const DELETE_AIRCRAFT = '/api/aircraft/';
const DELETE_AIRLINE = '/api/airline/';
const DELETE_AIRPORT = '/api/airport/';
const DELETE_HOTEL_PROVIDER = '/api/hotelProviders/';
const DELETE_ROOM_CATEGORY = '/api/roomsCategory/';
const DELETE_BAGGAGE_RULE = '/api/baggageRules/';
const DELETE_FARE_RULE = '/api/fareRules/';
const CHANGE_PRICE = '/api/orders/editDiscount/';
const DELETE_MANAGER = '/api/tourManagers/';

const PRIVATE_API = {
	SEARCH_FLIGHT,
	SEARCH_HOTEL,
	SEARCH_PACKAGE,
	PRE_ORDER,
	PRE_ORDER_HOTEL,
	CANCEL_PRE_ORDER,
	CANCEL_PRE_ORDER_HOTEL,
	PRE_ORDER_PACKAGE,

	CANCEL_PACKAGE_PRE_ORDER,
	CANCEL_BOOKING,
	CENCEL_BOOKING_HOTEL,
	CANCEL_PACKAGE_BOOKING,
	BOOKING_TO_TICKETING,
	BOOKING_TO_TICKETING_HOTEL,
	BOOKING_TO_TICKETING_PACKAGE,
	REFUND_TICKET,
	ORDER_DATE,
	REFUND_HOTEL,
	REFUND_PACKAGE,
	SPLIT_TICKET,
	SPLIT_PACKAGE,
	PROFILE_API,
	ADD_BALANCE,
	USE_BALANCE,
	HOTEL_PROVIDERS,
	HOTELS,
	ROOMS,
	SERVICES,
	FLIGHT_REPORTS,
	CHANGE_PRICE,
	CHANGE_PASSWORD,
	REMOVE_PASSENGER,

	UPDATE_ORDER,
	UPDATE_ORDER_PASSENGER_NAME,
	UPDATE_ORDER_HOTEL,
	UPDATE_PACKAGE_ORDER,
	UPDATE_AGENT,
	UPDATE_FLIGHT,
	UPDATE_FLIGHT_CLASS,
	UPDATE_FLIGHT_TEMPLATE,
	UPDATE_FLIGHT_CLASS_TEMPLATES,
	UPDATE_MANAGER,
	UPDATE_AIRPORTS,
	UPDATE_PROVIDERS,
	UPDATE_AIRCRAFT,
	UPDATE_AIRLINE,
	UPDATE_FARE_RULE,
	UPDATE_BAGGAGE_RULE,

	GET_ORDERS,
	GET_ORDERS_HOTEL,
	GET_PACKAGE_ORDERS,
	GET_AGENTS,
	GET_AGENTS_BY_ID,
	GET_FLIGHTS,
	GET_FLIGHT_CLASSES,
	GET_HISTORY,
	GET_HOTEL_CATEGORY,
	GET_HOTEL_ROOM_MEAL,
	GET_ROOM_CATEGORY,
	GET_AIRPORTS,
	GET_AIRLINES,
	GET_AIRCRAFTS,
	GET_FLIGHT_TEMPLATES,
	GET_FLIGHT_CLASS_TEMPLATES,
	GET_COMPANY_SETTINGS,
	GET_TICKET_SETTINGS,
	GET_SMTP_SETTINGS,
	GET_BAGGAGE_RULES,
	GET_FARE_RULES,
	GET_BALANCE_HISTORY,
	GET_MANAGERS_LIST,

	CREATE_FLIGHT,
	CREATE_FLIGHT_CLASS,
	CREATE_AIRLINES,
	CREATE_AIRCRAFT,
	CREATE_FLIGHT_TEMPLATES,
	CREATE_FLIGHT_CLASS_TEMPLATES,
	CREATE_COMPANY_INFO,
	CREATE_FARE_RULE,
	CREATE_BAGGAGE_RULE,
	CREATE_AIRPORTS,
	CREATE_ORDER,
	CREATE_ORDER_PACKAGE,
	CREATE_ORDER_HOTEL,
	CREATE_MANAGER,
	CREATE_AIRPORT,
	CREATE_COMPANY_SETTING,
	CREATE_TICKET_SETTING,
	CREATE_SMTP_SETTING,

	DELETE_ROOM_CATEGORY,
	DELETE_FLIGHT,
	DELETE_FLIGHT_CLASS,
	DELETE_FLIGHT_TEMPLATE,
	DELETE_FLIGHT_CLASS_TEMPLATES,
	DELETE_AIRCRAFT,
	DELETE_AIRLINE,
	DELETE_AIRPORT,
	DELETE_BAGGAGE_RULE,
	DELETE_FARE_RULE,
	DELETE_HOTEL_PROVIDER,
	DELETE_MANAGER,

	GET_TIME_LIMITS,
	GET_CURRENCY_TYPES,
	NOTIFICATIONS,
	CURRENCY,
	CURRENCY_MARGIN_TYPES,
	CREATE_TIME_LIMITS,
	UPDATE_TICKET_SETTING,
	UPDATE_COMPANY_SETTINGS,
	UPDATE_SMTP_SETTING,
	UPDATE_TIME_LIMITS
};

export default PRIVATE_API;
