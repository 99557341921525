import axiosClient from 'utils/axiosClient';
import api from 'api';
interface PassengersInfoTypes {
	name: string;
	surname: string;
	passportNumber?: string;
	passportExpirationDate?: string;
	gender?: string;
	dob?: string;
	seats?: string;
	extra?: string;
	passengerType: string;
	title: string;
	id: string;
}

/**
 * Get
 */

export interface SearchProps {
	travelType?: string;
	passengerTypeAdults?: number;
	passengerTypeChild?: number;
	passengerTypeInfant?: number;
	departureFrom?: string;
	destinationTo?: string;
	departureDate?: string;
	returnDate?: string;
}
export interface SearchPackageProps {
	travelType?: string;
	passengerTypeAdults?: number;
	passengerTypeChild?: number;
	passengerTypeInfant?: number;
	departureFrom?: string;
	destinationTo?: string;
	departureDate?: string;
	returnDate?: string;
	region?: string;
	hotel?: string;
	stars?: string;
}

export interface SearchHotelProps {
	passengerTypeAdults: number;
	passengerTypeChild?: number;
	passengerTypeInfant?: number;
	country?: string;
	region?: string;
	periodStart: string;
	periodEnd: string;
	category?: string;
}

const searchFlight = async (data: SearchProps) => {
	const response = await axiosClient.post(api.PRIVATE.SEARCH_FLIGHT, data);
	return response.data;
};
const searchPackage = async (data: SearchPackageProps) => {
	const response = await axiosClient.post(api.PRIVATE.SEARCH_PACKAGE, data);
	return response.data;
};
const searchHotels = async (data: SearchHotelProps) => {
	const response = await axiosClient.get(api.PRIVATE.SEARCH_HOTEL, {params: data});
	return response.data;
};

const searchHotelById = async (id: string, data: SearchHotelProps) => {
	const response = await axiosClient.get(api.PRIVATE.SEARCH_HOTEL + '/' + id, {params: data});
	return response.data;
};
const searchHotelByPnr = async (pnr: string) => {
	const response = await axiosClient.get(api.PRIVATE.SEARCH_HOTEL + '/pnr/' + pnr);
	return response.data;
};

export interface PreOrderFlightProps {
	departureFlightId: string;
	departureClassId: string;
	returnFlightId?: string;
	returnClassId?: string;
	travelType: string;
	passengerTypeAdults: number;
	passengerTypeChild: number;
	passengerTypeInfant: number;
}

export interface OrderFormTypes {
	pnr?: string;
	ticketStatus: string;
	contactPersonEmail: string;
	contactPersonFullName: string;
	contactPersonTelephone: string;
	contactPersonAddres: string;
	agentId: string | null;
	comment?: string;
	paymentType?: string;
	sputnikManager: any;
	partialPayment?: string;
	partialCurrency?: string;
	passengersInfo: PassengersInfoTypes[];
	penaltyAmount?: number | null;

	passengerTypeAdults?: number | null;
	passengerTypeChild?: number | null;
	passengerTypeInfant?: number | null;
	hotelId?: string | null;
	category?: string | null;
	periodStart?: string | null;
	periodEnd?: string | null;

	services?: string[] | null;
}

const preOrderFlight = async (data: PreOrderFlightProps) => {
	const response = await axiosClient.post(api.PRIVATE.PRE_ORDER, data);
	return response.data;
};

const preOrderHotel = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.PRE_ORDER_HOTEL, data);
	return response.data;
};

const preOrderPackage = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.PRE_ORDER_PACKAGE, data);
	return response.data;
};

const createOrder = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_ORDER, data);
	return response.data;
};
const orderDateChange = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.PRIVATE.ORDER_DATE + id, data);
	return response.data;
};

const createPackageOrder = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_ORDER_PACKAGE, data);
	return response.data;
};

const createOrderHotel = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_ORDER_HOTEL, data);
	return response.data;
};

const service = {
	searchFlight,
	searchPackage,
	preOrderFlight,
	preOrderHotel,
	createOrder,
	preOrderPackage,
	searchHotels,
	searchHotelById,
	orderDateChange,

	searchHotelByPnr,
	createOrderHotel,
	createPackageOrder
};

export default service;
