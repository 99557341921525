import {useContext, useEffect, Suspense} from 'react';
import {Container, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import {AuthContext} from 'context/AuthContext';
import {ProfileContext} from 'context/ProfileContext';
import {Switch, Route} from 'react-router-dom';
import {privateRoutes, publicRoutes} from './router';
import PrivateRoute from 'components/routes/PrivateRoute';
import PublicRoute from 'components/routes/PublicRoute';
import AppBarComponent from 'components/AppBar';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';
import Loading from 'components/Loading';
import NoMatch from './pages/publicPages/NoMatch';
import PasswordSetting from './pages/publicPages/PasswordSetting';
import services from 'services';
import {useLocation} from 'react-router-dom';
import clsx from 'clsx';
import './App.css';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		overflowY: 'hidden'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		overflow: 'auto',
		background: '#fff'
	},
	container: {
		paddingTop: 5,
		paddingBottom: 5,
		flexGrow: 1,
		position: 'relative',
		paddingLeft: 40,
		paddingRight: 40,
		'& .MuiContainer-root': {
			padding: 0
		},
		[theme.breakpoints.down('md')]: {
			paddingLeft: 15,
			paddingRight: 15
		}
	},
	searchPage: {
		padding: 0
	}
}));

export let ROLE_TYPE = '';

function App() {
	const classes = useStyles();
	const theme = useTheme();
	const location = useLocation();
	const {state: authState} = useContext(AuthContext);
	const {state: profileState, dispatch: profileDispatch} = useContext(ProfileContext);
	const isAppBarVisible = useMediaQuery(theme.breakpoints.up('md'));
	const ROLE =
		profileState.adminGroups && profileState.adminGroups !== '...'
			? profileState.adminGroups
			: profileState.role;

	const filteredRoutes = privateRoutes.filter(el => el.role.includes(ROLE));
	useEffect(() => {
		const userId = authState.userId;
		if (userId) {
			services.PRIVATE_SERVICES.AGENTS.getAgentsByID(userId).then(res => {
				profileDispatch({
					type: 'PROFILE',
					payload: res.result
				});
			});
		}
	}, [profileDispatch, authState.userId]);

	useEffect(() => {
		if (authState.isAuthenticated) {
			const params = {
				startDate: new Date().toISOString().substr(0, 10),
				endDate: new Date().toISOString().substr(0, 10)
			};
			services.PUBLIC_SERVICES.EXCHANGE.getExchangeRange(params).then(res => {
				profileDispatch({
					type: 'EXCHANGE',
					payload: res.result
				});
			});
		}
	}, [profileDispatch, authState.isAuthenticated]);

	useEffect(() => {
		ROLE_TYPE = ROLE;
	}, [profileState]);

	if (authState.isAuthenticated && !profileState.role) return <Loading />;

	return (
		<div className={classes.root}>
			{authState.isAuthenticated !== undefined ? (
				<>
					{authState.isAuthenticated ? (
						<>
							{isAppBarVisible ? <AppBarComponent /> : null}
							<NavBar />
							{/* {location.pathname !== '/search' && <Breadcrumbs />} */}
						</>
					) : null}

					<main className={classes.content}>
						<Container
							maxWidth={false}
							className={clsx(
								classes.container,
								location.pathname === '/search' && classes.searchPage
							)}>
							<Suspense fallback={<Loading />}>
								<Switch>
									{publicRoutes.map((route: any, i: number) => (
										<PublicRoute key={i} {...route} authState={authState} />
									))}
									{authState.isAuthenticated
										? filteredRoutes.map((route: any, i: number) =>
												route.subMenu ? (
													route.subMenu.map((route: any, i: number) => (
														<PrivateRoute
															key={i}
															{...route}
															authState={authState}
														/>
													))
												) : (
													<PrivateRoute key={i} {...route} authState={authState} />
												)
										  )
										: privateRoutes.map((route: any, i: number) => (
												<PrivateRoute key={i} {...route} authState={authState} />
										  ))}
									<Route path="/passwordSetting" component={PasswordSetting} />
									<Route component={NoMatch} />
								</Switch>
							</Suspense>
						</Container>
						{!!localStorage.getItem('userId') && <Footer />}
					</main>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default App;
